<script>
import auth from "@/mixins/auth";
import { mapGetters } from "vuex";
export default {
  name: "app-bar-mobile",

  mixins: [auth],

  computed: {
    ...mapGetters("session", ["loggedIn"]),
  },
};
</script>

<template>
  <nav id="app-bar-mobile">
    <div class="navmenu--general">
      <router-link to="/propiedades">
        <span class="sicon-search"></span>
        <span class="text">Buscar</span>
      </router-link>
      <router-link :to="{ name: 'acc-watching' }">
        <span class="sicon-heart"></span>
        <span class="text">Guardados</span>
      </router-link>
      <router-link :to="{ name: 'acc-inbox' }" v-if="loggedIn">
        <span class="sicon-chat"></span>
        <span class="text">Buzón</span>
      </router-link>
      <router-link :to="{ name: 'acc-listings' }">
        <span class="sicon-ads"></span>
        <span class="text">Publica</span>
      </router-link>
      <router-link :to="{ name: 'acc-org' }" v-if="loggedIn">
        <span class="sicon-team"></span>
        <span class="text">Grupo</span>
      </router-link>
      <a v-if="loggedIn" @click.prevent="$emit('toggle-app-account')">
        <span class="sicon-user"></span>
        <span class="text">Cuenta</span>
      </a>
      <a v-else @click.prevent="authLogin()">
        <span class="sicon-user"></span>
        <span class="text">Log in</span>
      </a>
    </div>
  </nav>
</template>
